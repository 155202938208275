const OttawaData = [
	{
		name: 'BIR',
		documents: [
			{
				type: 'Ledger',
				description: 'BIR CAROUSEL TEST BOX PANEL STANDARD MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1iQ9U6jvFn4flulCBjrlgxP40L3jgm3onBwHLpk5X70k',
			},

			{
				type: 'Ledger',
				description: 'BIR CAROUSEL TEST BOX PANEL WIFI MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/17dIiXrdwdTbZdeOM0JK23Xx3oJhu9hZbP8V7h-hEpJw',
			},

			{
				type: 'Ledger',
				description: 'BIR CAROUSEL TEST BOX PANEL WIFI MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1pyKbOUip82TK0J5hw-pUIjiHezImdx-Gs8-hIiAYEro',
			},

			{
				type: 'Ledger',
				description: 'BIR CAROUSEL TEST BOX PLC PANEL MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1xmDfwGFZJw7h9eDH3uxl5gyx9uVpEvKF7nmp8rzU4fM',
			},

			{
				type: 'Ledger',
				description: 'BIR DOOR TEST AND ACU PANEL MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1nSVkYXSzY8c3cpBBmhp8ABC6-OqUrOqkrBy5RBWlszg',
			},

			{
				type: 'Ledger',
				description: 'BIR HI-POT PANELS MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1lQzEDR4q1cpGfiauJJt3J4GXsKUdv4s4oA4K1FZ1AGE',
			},

			{
				type: 'Ledger',
				description: 'BIR HMI PANELS MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1B48R13n1VHWNonIlJ01Y4BX-ww2SXPNwJHoMjZVXL7E',
			},

			{
				type: 'Ledger',
				description: 'BIR ICE MAKER PANEL MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1Ko59AzE-rPdg3pSCsYzIm5jXLU9yVtErc30ScS4UFrQ',
			},

			{
				type: 'Ledger',
				description: 'BIR PRE-CABINET TEST PANEL MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1LvLnxOlxastv5v5AWiTQgtJuSeLWqPtvJTMCMXjYugs',
			},

			{
				type: 'Ledger',
				description: 'BIR REPAIR BAY CONTROL PANEL MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1ehdmRe-bJWwgwdVVHmp6EtVXhsOhbEkZ7l41n4gEM9I',
			},

			{
				type: 'Ledger',
				description: 'BIR REPAIR BAY TEST STATION PANELS MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1j1lOLsNv-pqA56BpADNrn1kjId6H0d1UKVeJupd0Zes',
			},

			{
				type: 'Ledger',
				description: 'BIR TEST PLC PANEL MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/13-jSi-6rcZo1bE9a0vzaueHIwPph0cavEEiELlZ8Qs8',
			},

			{
				type: 'Ledger',
				description: 'BIR WATER DISPENSER UNIT POWER BOX MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/15MGONqBGAbBB8a92lPKUZvkk0_gK2xRn9B8QfHezQfY',
			},

			{
				type: 'Ledger',
				description: 'BIR WIFI PANEL MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1HrGjRRb7TVV-GSpGiGaacWctWi8B6ivTZzOETDHX7Qk',
			},

			{
				type: 'SMP',
				description: 'SMP-1001 - Allen Bradley 800TC Mushroom Head Push Button Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1IBY07nb0hue6Sjt8LQeJ_n8k5460c9YOVjjhpgS11qM',
			},

			{
				type: 'SMP',
				description: 'SMP-1002 - Allen Bradley 1489-M1 Miniature Circuit Breaker Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1mf357z84zJSgWg4HWvhOs8nV344smsAAthBD9xMyQCs',
			},

			{
				type: 'SMP',
				description: 'SMP-1003 - Allen Bradley 1492-FB1C30-D1 Fuse Holder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1I8Cug7rCm9lfgUTGV1GrozW86OW84XcHlB9kinT9_DY',
			},

			{
				type: 'SMP',
				description: 'SMP-1004 - Allen Bradley 2711P-T15 PanelView Plus 7 Standard Terminal Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1tCdF2uNwJEfEeP4gAiVgaeNSJVezf8eygzM-kiZ8zt8',
			},

			{
				type: 'SMP',
				description: 'SMP-1005 - Allen Bradley 5069-AENTR Compact I/O Slim Ethernet IP Adapter Replacement',
				link: 'https://docs.google.com/spreadsheets/d/15ozEaQ36_cLZEcr2Rqk_l2CPWIbnvBfKcYed6d7JSZw',
			},

			{
				type: 'SMP',
				description: 'SMP-1006 - Allen Bradley 5069-IB8S Compact I/O Safety Input Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1VD36GXh33Oc8mtx4Y_clYmYUd-TaGOAQ5cIJ93dAS4A',
			},

			{
				type: 'SMP',
				description: 'SMP-1007 - Allen Bradley 5069-IB16 Compact I/O 16 Channel 24V Input Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1mF4X-3-IC4weoU4R_DbtDXKHDBYje0xVLc6KDljQYWE',
			},

			{
				type: 'SMP',
				description: 'SMP-1008 - Allen Bradley 5069-IF8 Compact I/O Analog Input Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/12F7_fT3HUgZ7iFrn9U-9XSkqDXXeMLUyJHT4XCupyMQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1009 - Allen Bradley 5069-OB8 Compact I/O Discrete Output Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1q1DcCfVPnH3czFZtyjbYaCgox1nSPFSKgdQ-NxnSBls',
			},

			{
				type: 'SMP',
				description: 'SMP-1010 - Allen Bradley 5069-OBV8S Compact I/O Safety Output Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1sdgxjuDgb3KDK2jzG8Xf8013sJa1vX1Hx-EtwSGJT68',
			},

			{
				type: 'SMP',
				description: 'SMP-1011 - Banner K50 Multicolor Indicator Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1lQ3SSlKbNYD77SgtG3FDxO9pa8ls_YKzaK9uOB3Vsj4',
			},

			{
				type: 'SMP',
				description: 'SMP-1012 - Hirschmann Spider 8-Port PoE Unmanaged Ethernet Switch Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1rajMV5RBr0x2Qukw6umQi5S6vTXLZYhzaq14GrF-Tyc',
			},

			{
				type: 'SMP',
				description: 'SMP-1013 - Ohio Semitronics GW5 Watt Transformer Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1UNT7i2tadIDN1DQkd35Pnev3J3km7fqK9x7VTggjgEY',
			},

			{
				type: 'SMP',
				description: 'SMP-1014 - SOLA SCD30S5-DN Power Supply Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1hpijkd2v0Qj_EruH72ogzTHjlw8NBY-jbjJKC5mgFVQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1015 - SOLA SDN10-24-100P Power Supply Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1GSwlk-njN9NoG2zHSv2k6bcHto4fgFumRkeurhcKYQI',
			},

			{
				type: 'SMP',
				description: 'SMP-1016 - Veris TW2TAXA Ambient Temperature Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1G-mL8LHN0M66gzyDD_T3iUFYxOuEtKi2smfdQjaciqQ',
			},
		],
	},
	{
		name: 'Door Fill',
		documents: [
			{
				type: 'Manual',
				description: 'Fill Assembly Process Operator and Maintenance Manual',
				link: 'https://interactivetrainingsystems.com/customer/whirlpool/whirlpool-v2/docs/ottawa/door_fill/FILLING_ASSEMBLY_PROCESS_OPERATION_&_MAINTENANCE_MANUAL.pdf',
			},

			{
				type: 'Ledger',
				description: 'Door Fill Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1OgH3qXDhSHuqp2XV-Hd1ryxSHZO3bdZcwI1eHuL2a4k',
			},

			{
				type: 'Calendar',
				description: 'Door Fill AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1eYUg9Hv3jNXlRJ-SHi5cy7mKVpxmMJlGs6FR5aZHihQ',
			},
	
			{
				type: 'CILR',
				description: 'Door Fill Route Map',
				link: 'https://docs.google.com/presentation/d/1-bkPLi-8_9O5COddu4lbZTsPDMaLFPIFiQ3FLGRrUVs',
			},

			{
				type: 'SMP',
				description: 'SMP-1000 - E021200-010 EOAT DOCK STATION GUIDE BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1Ni48eK_qOVDF9hu7VwxR6UbEWkgUHCPnlmkzLGToPCs',
			},

			{
				type: 'SMP',
				description: 'SMP-1001 - E021200-010 EOAT DOCK STATION STOPPER BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1MuguNory5yD_8-ThloocHAPXrwEhNl0u4uEO0CPEcD4',
			},

			{
				type: 'SMP',
				description: 'SMP-1002 - E021200-010 HOLDING TABLE SUPPORT BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1uxdqHRxYFrY7dJJ9i30KRFrVaNWgHyd2blZzQLBgcCE',
			},

			{
				type: 'SMP',
				description: 'SMP-1003 - E021200-010 HOLDING TABLE CENTER PALLET BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1x_YVi17H9hFrB0PUrQDfWykNItykyUDyGxpjizP1gGc',
			},

			{
				type: 'SMP',
				description: 'SMP-1004 - E021200-010 HOLDING TABLE CORNER PALLET BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1F4Z07zFxZ0jdt0DT7oxZ2gPakb4aYeU085qKDuScKno',
			},

			{
				type: 'SMP',
				description: 'SMP-1005 - E021200-010 HOLDING TABLE CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1bvdm-jZjeoNC-InyEhuyEZjqS7pAFsnQHKawVU16ueA',
			},

			{
				type: 'SMP',
				description: 'SMP-1006 - E021200-010 SCALE TABLE SUPPORT BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1uRvX9Vkap6W6OqZZuANE38dHGIyvD5kjA_BTm6Xfwmk',
			},

			{
				type: 'SMP',
				description: 'SMP-1007 - E021200-010 SCALE TABLE CENTER PALLET BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1s2XLocPfYHOZ-wKeE3ldkqZG2dZmAUj3nqPvvfRFs7w',
			},

			{
				type: 'SMP',
				description: 'SMP-1008 - E021200-010 SCALE TABLE CORNER PALLET BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1ybzqNvfpZhVIsYkVEyrZb-aZR5dl6uRzDPioRRffP9U',
			},

			{
				type: 'SMP',
				description: 'SMP-1009 - E021200-030 UPPER VIB FIXTURE EOAT ATI TOOL PLATE REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1zHhJfTILYUYSV4mMVIPqGM8otKdofYYlAUCHzOYWU0I',
			},

			{
				type: 'SMP',
				description: 'SMP-1010 - E021200-030 GRIPPER CLAMP ARM REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1LqPrbQHPSmvEoA80aSUJUb3oyFLlDUjdsF669RyTUZs',
			},

			{
				type: 'SMP',
				description: 'SMP-1011 - E021200-030 GRIPPER CLAMP ARM CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1ULVi-AAVtjmKYM9jN1Qp8G4q66UzAj8n2IbV8P_1TIE',
			},

			{
				type: 'SMP',
				description: 'SMP-1012 - E021200-030 UPPER VACUUM TOOLING SUCTION CUP GUIDED CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/10W-9Rp_IX9AgQMx3SPxmjYAnJu32BL2FyxPur0ZSaqw',
			},

			{
				type: 'SMP',
				description: 'SMP-1013 - E021200-030 UPPER VACUUM TOOLING SUCTION CUP REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1wcYP7OvEx5bfzUxKB_40iudd837XqSSkKdIUXdWNhFs',
			},

			{
				type: 'SMP',
				description: 'SMP-1014 - E021200-030 LOWER VACUUM TOOLING SUCTION CUP REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1PXInCQnEjaxwUmDQp2DLxldnscD8HQfk_ufESDsX4FA',
			},

			{
				type: 'SMP',
				description: 'SMP-1015 - E021200-030 LOWER VACUUM TOOLING SUCTION CUP GUIDED CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/14EDVXPMuBxKpFsEWgfj0iPDUKxhtOp9GBP8Chp-_PRU',
			},

			{
				type: 'SMP',
				description: 'SMP-1016 - E021200-030 LOWER VACUUM TOOLING SCHUNK CLAMP REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1zgcJBQWqDLQXp0fiomDyMF2y6EJyUdaN9smx9SGs_Qo',
			},

			{
				type: 'SMP',
				description: 'SMP-1017 - E021200-040 BASE ASSEMBLY CORNER PALLET BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1OtSU9Om7U5xajIEJkMzgwIWtrDsTmhjbO6hsNdHSynM',
			},

			{
				type: 'SMP',
				description: 'SMP-1018 - E021200-040 BASE ASSEMBLY CENTER PALLET BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1uLcMVvPmt0VOoocfoDcKLcKE1NuU4tPzAvmaeHwYoEM',
			},

			{
				type: 'SMP',
				description: 'SMP-1019 - E021200-040 BASE ASSEMBLY SUPPORT BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1lgkpNLxLz6rEMuyeQBoCmWyDT-o-oOpDCER_v1eEOX8',
			},

			{
				type: 'SMP',
				description: 'SMP-1020 - E021200-040 BASE ASSEMBLY CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1Bv5sntuF2XyGl0r0PF8z9k5sQb5jDvPEe7VZPPqNtTg',
			},

			{
				type: 'SMP',
				description: 'SMP-1021 - E021200-060 CART GUIDE COMPACT CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1rApX7b_xs-KiR889OmISNdJBWS2nWkakv0Wbu-HRHQE',
			},

			{
				type: 'SMP',
				description: 'SMP-1022 - E021200-060 MAINTENANCE FIXTURE REST PAD REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1aYVAgYG1CXqZx3C59p8spBJByPiWOi0TeQs81sO67tw',
			},

			{
				type: 'SMP',
				description: 'SMP-1023 - E021200-060 MAINTENANCE FIXTURE GUIDE BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1Xqbm3zOMlcezgAtYF67DIi5y953-losOUn3JHYKqAQo',
			},

			{
				type: 'SMP',
				description: 'SMP-1024 - E021200-060 MAINTENANCE FIXTURE PIN BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1G8whU9XRXmYG3_CxaST6V-8Gsak1yBmFopeh6fV6YVg',
			},

			{
				type: 'SMP',
				description: 'SMP-1025 - E021200-060 MAINTENANCE FIXTURE REST BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/14b36JSKoeziDyDTuaLzX2vzI094XGGc28cOs3cBpTuE',
			},

			{
				type: 'SOP',
				description: 'SOP-C01 - SCE ENCLOSURE CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1MXasMgZuI0cLe-x6AeHIil3UJ5cqp2fopewIUYnTUCk',
			},

			{
				type: 'SOP',
				description: 'SOP-C02 - FANUC CONTROLLER CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1GrAScZ507du8Hvvnp8qMO0Xfldw_U2TyV4ApZWdC8vg',
			},

			{
				type: 'SOP',
				description: 'SOP-C03 - GUARDING CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/12UNR6zo_lNpuZF1bJmXh24Zjb3RYvc1HuzbSFS8y7aI',
			},

			{
				type: 'SOP',
				description: 'SOP-C04 - LOCTITE CARTRIDGE DISPENSER CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1yfQ9nU-caAL6-5_3rp3Mg5Rc1amUh4cQbm8y1XMY9QU',
			},

			{
				type: 'SOP',
				description: 'SOP-C05 - FANUC SCARA ROBOT CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1tbbh1IWhU-_1qQpL-kbMxgwDty6XmJdp8hSjIK7q9nU',
			},

			{
				type: 'SOP',
				description: 'SOP-C06 - LOCTITE ROTOR PUMP CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1Oz3SuJo8jyBOWmj4LlepeBRDDmz0cebr6j2h_7mL030',
			},

			{
				type: 'SOP',
				description: 'SOP-C07 - FANUC COMPACT CONTROLLER CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1TU96kdonYm1T9VzOnln-p6prJ6iMq_yQhPnG1v94v9s',
			},

			{
				type: 'SOP',
				description: 'SOP-C08 - HMI PANEL CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/18EjCWHp6wWzT69hZhaxbbl_m7Mzyfg82i4GM8zoIn8s',
			},

			{
				type: 'SOP',
				description: 'SOP-I01 - FANUC CONTROLLER INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1xT887vpHFP4-WV_9kUjt6ol1LfFMJCAQPNNgT76WHWY',
			},

			{
				type: 'SOP',
				description: 'SOP-I02 - VIBRATION BASE INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1qC3QWqwO-eZuxcVRgYamYOoSc4Z2CLdnFP7yqZ--c0M',
			},

			{
				type: 'SOP',
				description: 'SOP-I03 - LOCTITE CARTRIDGE DISPENSER INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1aRY0n6DPqgVDHlN__RdShPrM7X4XJ4YfKxWFmkWENbo',
			},

			{
				type: 'SOP',
				description: 'SOP-I04 - FANUC SCARA ROBOT INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1NucAyaCV4SS5l59g_GSsc9e7Wxh7BBk29NbqMq4L1aQ',
			},

			{
				type: 'SOP',
				description: 'SOP-I05 - LOCTITE ROTOR PUMP INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1lD4xdT1doHC5BAzzgD73I1nLtiGDrmHq1ruYhDxS9oE',
			},

			{
				type: 'SOP',
				description: 'SOP-I06 - FANUC COMPACT CONTROLLER INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1UvLN5NNXPHzLEc4mVRgg0NAoD-HXBT_u01JMuz6J83M',
			},

			{
				type: 'SOP',
				description: 'SOP-S01 - HMI EMERGENCY STOP TEST',
				link: 'https://docs.google.com/spreadsheets/d/1fiajqSu77CJp5VARF9GfM6FYd1UZy-tNhoJ8RAMxgKk',
			},
		],
	},
	{
		name: 'Door Structure',
		documents: [
			{
				type: 'Manual',
				description: 'Fill Assembly Process Operator and Maintenance Manual',
				link: 'https://interactivetrainingsystems.com/customer/whirlpool/whirlpool-v2/docs/ottawa/door_structure/STRUCTURAL_DOOR_ASSEMBLY_OPERATION_&_MAINTENANCE_MANAUL.pdf',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Electrical Cabinets Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1B5C1Q4AZcc6nV6QasU528X19fett2hX1NkcsnHdfclU',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 010 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/16AosX0jvf5bYORJxIbWdQ6CoABZRlkdTnhDMushoziI',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 020 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1YSs3rM9xFVL-cTMLencl81arOr4aQ3-660CRXscLa3g',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 030 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1Kfpp9QpmLfdy_N6aJeUzaPsOHojNgCfMRVFuEZ2mPuE',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 040 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1JvplnNn28tCtTxkpQU_Eh8_FP62vzmGeW2gyiB5xWok',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 050 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1zSeglmJYnVXkbuuJH7NfdMPwySUBVjH6Sch3f4bdMR8',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 060 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1yFWPjOyFMfcmUMMly-UuagkWObCnZesXClWZjAMBzmQ',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 300 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1AvtKmYCEYWBtWbG2z89jRwZ4T3S_oQOMZu9t20bMPd0',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 310 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1Hk5aTzbX5dnfKyN_YTWXkx5oW5-iSISG8V7bnE1B7r8',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 320 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1Q4DatuHGzd_hJdJcK2kUhkSvKHpieX8ym9jxsGSQQJI',
			},

			{
				type: 'Ledger',
				description: 'Door Structure Station 330 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1xjhfhPaQLKA3VLrhkFcfNA_L_3E6rUzAzqa3HZdOrnM',
			},

			{
				type: 'SMP',
				description: 'SMP-1000 - FANUC CONTROLLER CLEANING PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/1iCUoExxTl7peWKdbdCNpOB2wMn3i8t6FcPk9x6HqqTc',
			},

			{
				type: 'SMP',
				description: 'SMP-1001 - FANUC CONTROLLER INSPECTION PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/1BBpr0mPT-RpUjM70Yj1opC5ZY7NL4lUsmJLamk3CQH0',
			},

			{
				type: 'SMP',
				description: 'SMP-1002 - MAIN PDP ENCLOSURE CLEANING PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/1AIIPmcv9QafFekJj-SbeVtMZTB0waFwUj1_LX7RfAMQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1003 - RHINO ELECTRIC BULK UNLOADER CLEANING PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/14zdq541S4JFS8iJKD2nAOUNf2HntlakVtfvZ4gKDZ4I',
			},

			{
				type: 'SMP',
				description: 'SMP-1004 - RHINO ELECTRIC BULK UNLOADER INSPECTION PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/12GraTS8m2Hbry_OIal4hCxAyKBXcrpL6FFKVizF1Iy4',
			},

			{
				type: 'SMP',
				description: 'SMP-1005 - HMI PANEL CLEANING PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/1gmc3Gll2rHHW8gYaro0GfGg5Dj4K-aCqX794zCx8Dbg',
			},

			{
				type: 'SMP',
				description: 'SMP-1006 - HMI EMERGENCY STOP TEST PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/1zReWcXfkZkicycjQf2PyODXDTRjGtFHJYil5yWDo1hA',
			},

			{
				type: 'SMP',
				description: 'SMP-1007 - EOAT INSPECTION PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/1Rm4kp_A4Gjfd0y4mkDmnCLuNQJs1nUm7GXFgAMWbgq4',
			},

			{
				type: 'SMP',
				description: 'SMP-1008 - METZGAR CONVEYOR INSPECTION PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/1TPw54lvSYuhosTZuRP0U1OLu1O2-6d4GPxv0tX3JY24',
			},

			{
				type: 'SMP',
				description: 'SMP-1009 - METZGAR CONVEYOR CLEANING PROCEDURE',
				link: 'https://docs.google.com/spreadsheets/d/19MBFIaLLwRPiG4kZLY7FZ1-E7uurRF2L64zE2s79HJ4',
			},

			{
				type: 'SMP',
				description: 'SMP-1010 - E021000-010-000-02 ATI QC40 TOOL AND R26 ELECTRIC MODULE REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1jLYv4sgbL6_nPNxNLatMJMaJfDc41sfrURFEdlOe8eg',
			},

			{
				type: 'SMP',
				description: 'SMP-1011 - EO21000-010-006 PART NEST REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/151BOPMjY15rMUVc0sglCzM_yQiV5w4ideT2wxPi-prU',
			},

			{
				type: 'SMP',
				description: 'SMP-1012 - EO21000-010-011 PUSH PAD REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/16fbZc-gwcoQICQV7BYxXY65XpeRGUlmfDQfk0yGBlkQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1013 - E021200-010-000-02 SMC MXS8-10 SLIDE REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1Zlx5nKNFN1bDNfUvB9pKkNd8Whrg6iwsam3LKR2HeMA',
			},

			{
				type: 'SMP',
				description: 'SMP-1014 - E021000-010-000-03 PIAB F75 SUCTION CUP SUCTION CUP REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1sqX9GZ8EpjoxQbySyxUlmpnMYketpvEJLp9fFOa1px8',
			},

			{
				type: 'SMP',
				description: 'SMP-1015 - EO21000-030-031 PUSHER HEAD REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/152vHzNj9sZD1O2sgRZ9aTtKJQwa3SUfeMA0gQJ-zg4g',
			},

			{
				type: 'SMP',
				description: 'SMP-1016 - E021000-030-011 LOCATING BLOCK REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1x07KHhzpF8ciSdx_zHjr776L2q_lXzS8OyJYF1QkbJw',
			},

			{
				type: 'SMP',
				description: 'SMP-1017 - EO21000-050-000-02 TOLOMATIC MXP-P RODLESS CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1n0iq1Sch3PKn054Mq0H3IOADkDCUp4kI7MLijtwvVHU',
			},

			{
				type: 'SMP',
				description: 'SMP-1018 - EO21000-050-000-02 SMC STOPPER CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1iVgM0lhqAOseH8NXEwfa_sPNWL_GTjyIgyC3KVpYBr0',
			},

			{
				type: 'SMP',
				description: 'SMP-1019 - E021000-050-000-03 AIR KING INDUSTRIAL FAN REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1j1TpeZ25hYUHaBRbhzpXIOY3ReT8Ai91mUIWvUdBjL0',
			},

			{
				type: 'SMP',
				description: 'SMP-1020 - E021000-060-000-02 BOSCH REXROTH D95 TOOL HOLDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1M3D3ZOkQu9ylq9C2cvROILdIbWQVOCxe_4qgSawtYjQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1021 - EO21000-300 PANEL NEST REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/17NvCbuxPd-1zp3iz7-q1NeZR60fn8oHB2mJ89h_f3mk',
			},

			{
				type: 'SMP',
				description: 'SMP-1022 - EO21000-300 PALLET ASSEMBLY GIB REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/17H9gFg6m_bZbdRTaVJEfemlwniUTM3j3jtSxlTx1BeE',
			},

			{
				type: 'SMP',
				description: 'SMP-1023 - EO21000-300 TABLE SWIVEL CASTER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/16YtjPudWGt63sP8wn0kXkIjZ5qZ_WjHEHrwPw1esj0k',
			},

			{
				type: 'SMP',
				description: 'SMP-1024 - EO21000-300 PALLET STOPPER ASSEMBLY STOPPER CYLINDER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1xJY8RKCIjEl0lc2uK6yXkclUCQKn9a3TPhCfpuJu6ko',
			},

			{
				type: 'SMP',
				description: 'SMP-1025 - EO21000-300 LOAD STATION PALLET GUIDE REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/10Z8dIG78durcqIt9lZ2IomZZsNNmBdssH8oJjUsbqzw',
			},

			{
				type: 'SMP',
				description: 'SMP-1026 - EO21000-300-000-17 POP-UP ROLLER SKATE WHEEL REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1E1VvUkqg-J4P-f-ryRGu3-WhH63XS76TwDxTozt2EwA',
			},

			{
				type: 'SMP',
				description: 'SMP-1027 - EO21000-310-000-02 SWING MAGNETIC ASSEMBLY REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/11fc5JWqQXf83s0hyuHh9AZt7jOIe7Z9rpRTQatut47o',
			},

			{
				type: 'SMP',
				description: 'SMP-1028 - EO21000-310-000-02 SWING CLAMP ASSEMBLY REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1vkNNQSCkfrb22c8_E50H2_87VgtgHZIwKJiPUxhYomI',
			},

			{
				type: 'SOP',
				description: 'SOP-01 - FANUC CONTROLLER CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1yuXTVzaBf9FuLtM0zY5L7OnenZhpXXnJbl6TplljvcY',
			},

			{
				type: 'SOP',
				description: 'SOP-02 - FANUC CONTROLLER INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1P0Z2gaUUYefPBSRhyUpaIg2-pzILXx4t9_pItC-GVAU',
			},

			{
				type: 'SOP',
				description: 'SOP-03 - SCE ENCLOSURE CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/12YIVyigXJUiLVtXjyiAhe8e4eEEw-IrUOLQzekY7W84',
			},

			{
				type: 'SOP',
				description: 'SOP-04 - RHINO ELECTRIC BULK UNLOADER CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1F8HXeS985wzIk_tkRTh_Kbjk7OeuSWc5pKnubCjGKnM',
			},

			{
				type: 'SOP',
				description: 'SOP-05 - RHINO ELECTRIC BULK UNLOADER INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1FELlHRLrn7WnEBu8HMFBplpqz9Nr_hXTLQE5iKIHB3g',
			},

			{
				type: 'SOP',
				description: 'SOP-06 - HMI PANEL CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1kTFYHKiVP7O1mCPfXodtZ9ZiR4fnx-fhFXrSodj-6Wk',
			},

			{
				type: 'SOP',
				description: 'SOP-07 - HMI EMERGENCY STOP TEST',
				link: 'https://docs.google.com/spreadsheets/d/16Zh1Uo-oz7-8vXQwsaB66gcA8ZPOH0sKWQY3LFi0r8U',
			},
		],
	},
	{
		name: 'Material Handling',
		documents: [
			{
				type: 'Manual',
				description: 'Material Handling And Dryer Operator and Maintenance Manual',
				link: 'https://interactivetrainingsystems.com/customer/whirlpool/whirlpool-v2/docs/ottawa/material_handling/MATERIAL_HANDLING_AND_DRYER_OPERATION_&_MAINTENANCE_MANUAL.pdf',
			},

			{
				type: 'Ledger',
				description: 'Material Handling and Dryer Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1IcivWNDhkjkMr1JjsjHv-JHBFr66TO3VXKVzU2Rbxyc',
			},

			{
				type: 'Calendar',
				description: 'Material Handling and Dryer AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1EP24sp6QaXBnjlw4Xf2cQaKtRdNSPGc9hnLD0anlwr0',
			},

			{
				type: 'CILR',
				description: 'Material Handling and Dryer Route Map',
				link: 'https://docs.google.com/presentation/d/13Fkp8adHqV1-bjb8HJOnACLi4rwQAsS4gVG9a6UhQo8',
			},

			{
				type: 'SMP',
				description: 'SMP-1000 - EDWARDS VACUUM PUMP OIL LEVEL INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1NRPJW7XCr8UQQZtM281mlg1XaZv_TykUOUZnCPulQe8',
			},

			{
				type: 'SMP',
				description: 'SMP-1002 - GEMCO DRYER HOPPER MAIN DRIVE REDUCER OIL LEVEL INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/11hTIFMCRnzecDl6qvJ6s33JoAb0f1OFarHFo121RGb4',
			},

			{
				type: 'SMP',
				description: 'SMP-1003 - GEMCO DRYER HOPPER MAIN DRIVE REDUCER OIL CHANGE',
				link: 'https://docs.google.com/spreadsheets/d/1rHTPRSa-VoeSPcJqF2mAPLrTB7JCUCBlHugqWz88uPY',
			},

			{
				type: 'SMP',
				description: 'SMP-1004 - GEMCO DRYER HOPPER MAIN DRIVE BELT INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1FAsInMy5iJ3fPjw382dOV-MD_bPinUtJVzIdFV2tax4',
			},

			{
				type: 'SMP',
				description: 'SMP-1005 - GEMCO DRYER HOPPER PILLOW BLOCK BEARING LUBRICATION',
				link: 'https://docs.google.com/spreadsheets/d/1gKa54RcqLvbtECwMU9Ljpp09JshkXsbyc67kV6iUiiw',
			},

			{
				type: 'SMP',
				description: 'SMP-1006 - GEMCO DRYER HOPPER AIR BRAKE ASSEMBLY INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1qIuoAMKFCFkKww016NjQnpYgrHDsbA7kekNDR-DXjZk',
			},

			{
				type: 'SMP',
				description: 'SMP-1007 - GEMCO DRYER HOPPER ACCESS COVER ASSEMBLY INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/19jaWVGVVOX7xJfiasnO-6n4b9Yax4astRvlExGplfpQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1009 - GEMCO DRYER HOPPER HOT OIL ROTARY UNION INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1yWUURFkd88ssn-Zrg1Qni714NaPBYtfGYrIhqHdXqRU',
			},

			{
				type: 'SMP',
				description: 'SMP-1010 - GEMCO DRYER HOPPER AIR SYSTEM INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1IgwaNIJoTDRAi_IBYj42PWrZ6fEZ9RfTR_SXGw5W8U8',
			},

			{
				type: 'SMP',
				description: 'SMP-1011 - GEMCO DRYER HOPPER CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1Lv2Xam_ymuDJHgeeDyo8LbpwOXsT4mnL7bFuUdvlBEg',
			},

			{
				type: 'SMP',
				description: 'SMP-1012 - GEMCO DRYER HOPPER VACUUM PUMP INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1xHtFAkRQgZtehWlFcCEGFuez7r2SaJtzDD7sb511fM0',
			},

			{
				type: 'SMP',
				description: 'SMP-1013 - HIGH PRESSURE PUMP SEAL REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1cIwodwEGk4eXJwFRspjR8zrbh7rVWT7BP-2wucfp_1g',
			},

			{
				type: 'SMP',
				description: 'SMP-1014 - SUPPLY PUMP SEAL REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1hAJSZnEWN0ODtFyVuBDXg9OEvV03VBB62mMUjuJKJV4',
			},

			{
				type: 'SMP',
				description: 'SMP-1015 - BULK BAG DISCHARGER CLAMP ROD LINEAR BEARING REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1JB7pyQ-3HPNpZt8T3IFbhAL6injUk_OlQKyANBurAyM',
			},

			{
				type: 'SMP',
				description: 'SMP-1016 - BULK BAG DISCHARGER CARRIAGE LINEAR BEARING REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1MfSZFr1ji2PyXR84oNyao3XriWJtL9NmSJHb14vOSsQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1017 - BULK BAG DISCHARGER CARRIAGE LINEAR BEARING LUBRICATION',
				link: 'https://docs.google.com/spreadsheets/d/1y1llUzRfqTDn1f7ip_Zm5PBHUtpMg93vd5JpUf6UdOU',
			},

			{
				type: 'SMP',
				description: 'SMP-1018 - BULK BAG DISCHARGER MASSAGE PADDLER AND BANJO REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1iVgM0lhqAOseH8NXEwfa_sPNWL_GTjyIgyC3KVpYBr0',
			},

			{
				type: 'SMP',
				description: 'SMP-1019 - HOIST ASSEMBLY OIL CHANGE',
				link: 'https://docs.google.com/spreadsheets/d/1rwNkAF5ZurJ4lU8lac5Px0vg_MxM1lHYS6uVaam26Ns',
			},

			{
				type: 'SMP',
				description: 'SMP-1020 - HOIST ASSEMBLY OIL LEVEL INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1IbbGOIfXOqZML8GwOlbrcCHGOG-L2yweszWOyE12JrU',
			},

			{
				type: 'SOP',
				description: 'SOP-C01 - WEEKLY EDWARDS OVERPRESSURE PUMP MOTOR FAN AND VENT HOLES CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1BlONgKoRuYZO6i8PDyMNLNim7CK7QwPDSayhFCbYlcY',
			},

			{
				type: 'SOP',
				description: 'SOP-C02 - WEEKLY GEMCO DRYER HOPPER CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1dHknEA6R1NNiwUGM9EjEDm6FdY99TlshufK50QsEIUU',
			},

			{
				type: 'SOP',
				description: 'SOP-C03 - WEEKLY IPC ENCLOSURE CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1d094YbF0dbASDkQR-uA-KXywJQnxtJDbsQ9A47X3sKg',
			},

			{
				type: 'SOP',
				description: 'SOP-C04 - WEEKLY HMI PANEL CLEANING',
				link: 'https://docs.google.com/spreadsheets/d/1DB0Aa1ZFdJ77WNMZ2mfvTRqQpmgfOzcZkygehJAQFIM',
			},

			{
				type: 'SOP',
				description: 'SOP-I01 - MONTHLY EDWARDS OVERPRESSURE PUMP INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/19qYSjxiq0hEwcsOJg0tj3rJizRbITEq7v792fBICyME',
			},

			{
				type: 'SOP',
				description: 'SOP-I02 - MONTHLY GEMCO DRYER HOPPER INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1aRl8bx47H6-ZkmgOZ3luxrYBSVB5o5zK8DNbhhoKFDI',
			},

			{
				type: 'SOP',
				description: 'SOP-I03 - MONTHLY GEMCO DRYER HOPPER BULKHEAD INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1lFZr-_4SzKyorVUKl4BtTz678MsfIMRXdWpOoflgoRk',
			},

			{
				type: 'SOP',
				description: 'SOP-I04 - MONTHLY BAG DISCHARGE PNEUMATIC PANEL INSPECTION',
				link: 'https://docs.google.com/spreadsheets/d/1jteixTnX0dOXhahBfFCN8IMIG8bsRfWWxfAWRTS3ckE',
			},

			{
				type: 'SOP',
				description: 'SOP-L01 - GEMCO DRYER HOPPER PANEL LUBRICATION',
				link: 'https://docs.google.com/spreadsheets/d/1hIw_e_yDDdp9s_Eq17g7BOx430plM8DzzvLUWTpnFAk',
			},

			{
				type: 'SOP',
				description: 'SOP-S01 - HMI EMERGENCY STOP TEST',
				link: 'https://docs.google.com/spreadsheets/d/1H7Bh5D6GDXT_lGh2z_y6MBjAav9EmEoQm2P02X2lvco',
			},
		],
	},

	{
		name: 'Oberon',
		documents: [
			{
				type: 'Ledger',
				description: 'OBERON MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/10GZNeqzyKo-EbQCzoC7_udMCuxysOIp2xER2rRaUExk',
			},

			{
				type: 'SMP',
				description: 'SMP-1001 - Allen Bradley 1489-M Series Miniature Circuit Breaker Replacement',
				link: 'https://docs.google.com/spreadsheets/d/16GQMmiQq2Ffgf8E6ogvwEzclPxvC3lhFVff6DzZfm6s',
			},

			{
				type: 'SMP',
				description: 'SMP-1002 - Allen Bradley 1492-FB1C30-D1 Fuse Holder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1Y8AQz8ox40tja0V7EfPEweguvgxA67QYA6O3p_4gDpQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1003 - Allen Bradley 5069-OB8 Compact I/O Discrete Output Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1MHPaBjIzWMtqn5TE2YGm0SF40E9cbI2V5HfPpHvXCRI',
			},

			{
				type: 'SMP',
				description: 'SMP-1004 - Allen Bradley 5069-AENTR Compact I/O Slim Ethernet IP Adapter Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1JYnNMx3Epamc7ASirNJ8SDOBWdfqDtOhiXJy-Ln01rM',
			},

			{
				type: 'SMP',
				description: 'SMP-1005 - Allen Bradley 5069-OBV8S Compact I/O Safety Output Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1g1r0xIw8jxKEe7vkfNGWv2wwGa1W9PfyUVc62c3MO70',
			},

			{
				type: 'SMP',
				description: 'SMP-1006 - Allen Bradley 5069-IB16 Compact I/O 16 Channel 24V Input Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/11TKoZBz66F54YqkDSVi7gdX9IlFMpXByAUEKNzRYdW4',
			},

			{
				type: 'SMP',
				description: 'SMP-1007 - Allen Bradley 5069-IB8S Compact I/O Safety Input Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1mOrs-QLjxHfPi7DMjLK_LxSdrZgJexs3bdIORH5tw84',
			},

			{
				type: 'SMP',
				description: 'SMP-1008 - Banner K50 Multi Color Indicator Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ubSWH6Z20KSKj_fB_MOfy1CRmVxVBd6PXACaRRO_R3U',
			},

			{
				type: 'SMP',
				description: 'SMP-1009 - Hirschmann Spider 8-Port PoE Unmanaged Ethernet Switch Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1kpaNyyXotEZnyuQptVVHTzSd_UE4b8HD_YLiA9u_oco',
			},

			{
				type: 'SMP',
				description: 'SMP-1010 - Ohio Semitronics GW5 Watt Transformer Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1vKCGWjJM3F1SeoZhUk31IrI0_I8w5ANSiP3Tf10GpVQ',
			},

			{
				type: 'SMP',
				description: 'SMP-1011 - SOLA SCD30S5-DN Power Supply 5VDC 10AMP Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1a1874uZmH1aMRUF54MHehoFAoed6_EVqI45CpomVwgU',
			},

			{
				type: 'SMP',
				description: 'SMP-1012 - SOLA SDN10-24-100P Power Supply 24VDC 10 AMP Replacement',
				link: 'https://docs.google.com/spreadsheets/d/18xNticbXsmIxh8eoXcrYV-sfC9_avRRD-v9VuCS0_QY',
			},

			{
				type: 'SMP',
				description: 'SMP-1013 - Allen Bradley 2711P-T15 PanelView Plus 7 Standard Terminal Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1r1RrjFXbkJdSlkJFmQ4L0L71cBUHX8c61vl3HTSLm_g',
			},
		],
	},


	{
		name: 'Vertical Reciprocating Conveyor (VRC)',
		documents: [
			{
				type: 'Ledger',
				description: 'VERTICAL RECIPROCATING CONVEYOR (VRC) MACHINE LEDGER',
				link: 'https://docs.google.com/spreadsheets/d/1MIVVpvcSBpXkyCeGitfaNQWA2NiCY8XTyKepr4ynDZw',
			},

			{
				type: 'Calendar',
				description: 'VERTICAL RECIPROCATING CONVEYOR (VRC) AM CALENDAR',
				link: 'https://docs.google.com/spreadsheets/d/196cXV9oPTwHwZMfuneDlyj3u6iqBGi6yfHQOwa-rJEU',
			},

			{
				type: 'SMP',
				description: 'SMP01 - Miniature Circuit Breaker Replacement',
				link: 'https://docs.google.com/spreadsheets/d/175AfIOh4z8GMDf-VzY6K-7JPDOSXjK2I9IAknk0YJKc',
			},

			{
				type: 'SMP',
				description: 'SMP02 - Control Relay Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1tcNg8Cf0sDW2vPTomdAcFCHKK4ITWeXyENwkRncBBXI',
			},

			{
				type: 'SMP',
				description: 'SMP03 - Process Logic Controller Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ctyh1cATmT3R6sfTSmoVt7dySMt065FK8ihYPEisR-0',
			},

			{
				type: 'SMP',
				description: 'SMP04 - Variable Frequency Drive Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1SF14NEFikk_0aSoqxiF8lrj6b539p-oZ2EK6H11Xxnk',
			},

			{
				type: 'SMP',
				description: 'SMP05 - Power Supply Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1nQn4XfdpXtBRncKfvRxY5V3UzPXLg4E83iT9j4aHSwg',
			},

			{
				type: 'SMP',
				description: 'SMP06 - Gearbox Oil Check and Replacement',
				link: 'https://docs.google.com/spreadsheets/d/157TwdvZWU7drwToFXa0eI-KbljUXwkiU7T3kZXGwU6k',
			},

			{
				type: 'SMP',
				description: 'SMP07 - Pillow Block Bearing Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1mtxosqFTaGnbSjjok_4X-3jBaV32k-JC_WbUr6UkGgk',
			},

			{
				type: 'SMP',
				description: 'SMP08 - Semi-Annual Inspection of the VRC',
				link: 'https://docs.google.com/spreadsheets/d/1UFM-UctPS8AmGvcJvXjI9b5wI-0Xrv3cX9gls0zD6CI',
			},

			{
				type: 'SMP',
				description: 'SMP09 - Emergency Stop Test Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1f2NZvCUm5CBBvUbeoIo9dHFFfjCTZOfm28fQTyFxpvQ',
			},

			{
				type: 'SMP',
				description: 'SMP10 - Slack Cable Test Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1a18WvbnT0GF3dKsvwrayHryvA2zBEJCcc3hJtbJNzMw',
			},

			{
				type: 'SMP',
				description: 'SMP11 - Over-Travel Test Inspection',
				link: 'https://docs.google.com/spreadsheets/d/10Lh9372-neG9yIHTuiH7JzoNr-GWzasxyyMicJCSUVM',
			},

			{
				type: 'SMP',
				description: 'SMP12 - Position Switch Setting Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1QDFvyTcyo6X8a7MRBDbf_oAcfryl6SUhBYlpEDoVrgU',
			},

			{
				type: 'SOP',
				description: 'SOP - C01~C03 VRC Cleaning',
				link: 'https://docs.google.com/spreadsheets/d/1IkAy0Ii8dLFUGNbx7PBtS6NajjfA-XxN9JYdcNJFubU',
			},

			{
				type: 'SOP',
				description: 'SOP - I01~I21 VRC Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1t1dHtJCO-P5EC1piiWCi9nICPF64RBRA6fnidiiPzJg',
			},

			{
				type: 'SOP',
				description: 'SOP - L01~L02 VRC Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1ekl-zjdqkOsMBT4CAxJOH7AWO5zFiYQqE5wBHehSr58',
			},

			{
				type: 'SOP',
				description: 'SOP - R01~R04 VRC Refastening',
				link: 'https://docs.google.com/spreadsheets/d/1sMg0AbalVoVzdqMDISndvbaYiyfSahJgelwyrrXHB8w',
			},

			{
				type: 'SOP',
				description: 'SOP - S01~S04 VRC Safety Checks',
				link: 'https://docs.google.com/spreadsheets/d/1evpzu3SbGT-aqg_1at5p_5EXx1UDcT6_CApmD0Ri8sA',
			},

		],
	},
];

export default OttawaData;